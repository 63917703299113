import { getBundleFromQuote } from 'apiHelpers/quote/bundleCoverMapping';
import { CoverPetType, Quote } from 'apiHelpers/quote/quoteResponse';
import { CoverLevel } from 'helpers/businessConstants';
import { petType_DOG } from 'helpers/referenceDataConstants';
import { PaymentInstallmentCover } from '../paymentRequest';

const mapPetPaymentInstallmentCovers = (
  quote: Quote,
  coverLevel: CoverLevel,
  petType: string
): PaymentInstallmentCover[] => {
  const basePetCovers = quote.parentCover.map((coverSection) => ({
    coverSection,
    coverPurchasedIndicator: true,
  }));

  const covers = getBundleFromQuote(coverLevel, quote)?.covers;

  return basePetCovers.map((paymentCover) => ({
    coverSection: paymentCover.coverSection,
    coverPurchasedIndicator:
      covers?.some(
        (cover) =>
          (cover.coverCode === paymentCover.coverSection ||
            cover.cover.name === paymentCover.coverSection) &&
          cover.selected &&
          (petType === petType_DOG || cover.cover.petType !== CoverPetType.DOG)
      ) || false,
  }));
};

export default mapPetPaymentInstallmentCovers;
