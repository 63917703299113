import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { addYearsToDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { PageTitle } from 'helpers/eventTracking';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { CurrentQuote } from 'helpers/useCurrentQuote';
import {
  BoldParagraphSpan,
  DividerWithTopAndBottomMargin,
  LightParagraphSpan,
  PanelWithVerticalMargin,
  ParagraphText,
  RichTextWithVerticalMargin,
  StyledGrid,
  Subheading,
} from './styles';
import CoverSummaryPricePanel from '../../QuoteSummary/SummaryOfCover/CoverSummaryPricePanel';

type PaymentSummaryContent = {
  csPetCheckYourDetailsDetailsSection: {
    quote_reference_label: string;
    your_cover_dates: {
      policy_starts: string;
      policy_renewal_date: string;
    };
  };
  csPetMonthlyPayment: {
    payment_summary: {
      heading: string;
      or_pay_annually_text: string;
      payment_information: string;
    };
  };
  csPetAnnualPayment: {
    payment_summary: {
      heading: string;
      premium_tax_rate_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSection {
      quote_reference_label
      your_cover_dates {
        policy_starts
        policy_renewal_date
      }
    }
    csPetMonthlyPayment {
      payment_summary {
        heading
        or_pay_annually_text
        payment_information
      }
    }
    csPetAnnualPayment {
      payment_summary {
        heading
        premium_tax_rate_text
      }
    }
  }
`;

type PaymentSummaryProps = {
  isAnnualPayment: boolean;
  switchPaymentType: React.MouseEventHandler<Element>;
  quote: CurrentQuote;
};

const PaymentSummarySection: React.FC<PaymentSummaryProps> = ({
  isAnnualPayment,
  switchPaymentType,
  quote,
}) => {
  const {
    csPetCheckYourDetailsDetailsSection,
    csPetAnnualPayment,
    csPetMonthlyPayment,
  } = useStaticQuery<PaymentSummaryContent>(query);

  const richTextPlaceholderReplacer = replacePlaceholdersRichText(
    quotePlaceholders,
    quote
  );

  const startDate = new Date(quote.policyInfo?.coverStartDate || '');
  const renewalDate = addYearsToDate(startDate, 1);
  const sectionId = 'payment-summary-heading';

  return (
    <StyledGrid as="section" alignLeft id={sectionId}>
      <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
        <Subheading>
          {isAnnualPayment
            ? csPetAnnualPayment.payment_summary.heading
            : csPetMonthlyPayment.payment_summary.heading}
        </Subheading>
        <PanelWithVerticalMargin>
          <CoverSummaryPricePanel
            isAnnualPayment={isAnnualPayment}
            paymentOnClick={switchPaymentType}
          />
        </PanelWithVerticalMargin>
        {isAnnualPayment ? (
          <RichTextWithVerticalMargin
            pageTitle={PageTitle.Payment}
            html={richTextPlaceholderReplacer(
              csPetAnnualPayment.payment_summary.premium_tax_rate_text
            )}
            data-cy="premiumTaxRateText"
          />
        ) : (
          <RichTextWithVerticalMargin
            pageTitle={PageTitle.Payment}
            html={richTextPlaceholderReplacer(
              csPetMonthlyPayment.payment_summary.payment_information
            )}
            data-cy="monthlyPaymentInformation"
          />
        )}
        <ParagraphText data-cy="policyStartDate">
          <LightParagraphSpan>
            {csPetCheckYourDetailsDetailsSection.your_cover_dates.policy_starts}{' '}
          </LightParagraphSpan>
          <BoldParagraphSpan>{startDate.toLocaleDateString('en-GB')}</BoldParagraphSpan>
        </ParagraphText>
        <DividerWithTopAndBottomMargin />
        <ParagraphText
          data-cy="renewalDate"
          className={`${isAnnualPayment ? 'isAnnualPayment' : ''}`}>
          <LightParagraphSpan>
            {csPetCheckYourDetailsDetailsSection.your_cover_dates.policy_renewal_date}{' '}
          </LightParagraphSpan>
          <BoldParagraphSpan>{renewalDate.toLocaleDateString('en-GB')}</BoldParagraphSpan>
        </ParagraphText>
      </GridItem>
    </StyledGrid>
  );
};

export default PaymentSummarySection;
