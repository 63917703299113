import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const RichTextWithMargin = styled(RichTextWithModal)`
  margin: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(2)};
  `}

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(5)} ${spacing(4)} ${spacing(4)};
  `}
`;

export const StyledImage = styled.img`
  margin: ${spacing(2)} 0 ${spacing(-1)} ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin: 0;
    position: absolute;
    top: ${spacing(4)};
    right: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    top: ${spacing(6)};
    right: ${spacing(8)};
  `}
`;

export const StyledPanel = styled(SimplePanel)<{ children?: React.ReactNode }>`
  background-color: ${colors.neutral07};
`;
