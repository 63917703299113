import InfoCard from '@rsa-digital/evo-shared-components/components/InfoCard/InfoCard';
import {
  Heading,
  InfoCardRichText,
} from '@rsa-digital/evo-shared-components/components/InfoCard/InfoCard/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledInfoCard = styled(InfoCard)`
  border: None;
  margin: ${spacing(4)} 0 ${spacing(5)};
  padding: ${spacing(4)};
  border: solid 1px ${colors.neutral04};

  ${Heading} {
    margin: 0;
    ${fonts.headingSmall}

    ${mediaQuery.tabletPortrait`
      width: 83%;
    `}
  }

  ${InfoCardRichText} {
    margin-top: ${spacing(3)};
    ${fonts.paragraphSmall}
    ${mediaQuery.tabletPortrait`
      margin-top: ${spacing(4)};
      width: 83%;
    `}
  }
`;
