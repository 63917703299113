import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import MastercardLogo from 'assets/mastercard.svg';
import VisaLogo from 'assets/visa.svg';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import UppPayment from 'components/UppPayment';
import { CurrentQuote } from 'helpers/useCurrentQuote';
import SectionHeadingWithIcons from '../../SectionHeadingWithIcons';

type AnnualPaymentContent = {
  csPetAnnualPayment: {
    card_details: {
      heading: string;
    };
  };
};

export const query = graphql`
  query {
    csPetAnnualPayment {
      card_details {
        heading
      }
    }
  }
`;

type AnnualPaymentSectionProps = {
  quote: CurrentQuote;
};

const AnnualPaymentSection: React.FC<AnnualPaymentSectionProps> = ({ quote }) => {
  const { heading } = useStaticQuery<AnnualPaymentContent>(
    query
  ).csPetAnnualPayment.card_details;

  const sectionId = 'cardPaymentSection';

  return (
    <section aria-labelledby={sectionId}>
      <Grid alignLeft>
        <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
          <SectionHeadingWithIcons id={sectionId} heading={heading}>
            <img src={MastercardLogo} alt="Mastercard Logo" />
            <img src={VisaLogo} alt="Visa Logo" />
          </SectionHeadingWithIcons>
        </GridItem>
      </Grid>
      <UppPayment quote={quote} />
    </section>
  );
};

export default AnnualPaymentSection;
