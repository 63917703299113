import { petApiService } from './apiService';
import {
  ConfirmAnnualPaymentRequest,
  ConfirmMonthlyPaymentRequest,
  StartAnnualPaymentRequest,
  StartMonthlyPaymentRequest,
  UpdateMonthlyPaymentSechduleRequest,
  ValidateDirectDebitRequest,
} from './payment/paymentRequest';
import {
  BankDetails,
  StartAnnualPaymentResponse,
  StartMonthlyPaymentResponse,
  UpdateMonthlyPaymentScheduleResponse,
} from './payment/paymentResponse';

type PaymentClient = {
  startAnnualPayment: (
    request: StartAnnualPaymentRequest
  ) => Promise<StartAnnualPaymentResponse>;
  confirmAnnualPayment: (request: ConfirmAnnualPaymentRequest) => Promise<void>;
  startMonthlyPayment: (
    request: StartMonthlyPaymentRequest
  ) => Promise<StartMonthlyPaymentResponse>;
  updateMonthlyPaymentSchedule: (
    request: UpdateMonthlyPaymentSechduleRequest
  ) => Promise<UpdateMonthlyPaymentScheduleResponse>;
  validateDirectDebit: (request: ValidateDirectDebitRequest) => Promise<BankDetails>;
  confirmMonthlyPayment: (request: ConfirmMonthlyPaymentRequest) => Promise<void>;
};

const paymentClient: PaymentClient = {
  startAnnualPayment: (request: StartAnnualPaymentRequest) =>
    petApiService.post<StartAnnualPaymentResponse>('payments/annual/start', request),
  confirmAnnualPayment: (request: ConfirmAnnualPaymentRequest) =>
    petApiService.post('payments/annual/confirmation', request),
  startMonthlyPayment: (request: StartMonthlyPaymentRequest) =>
    petApiService.post<StartMonthlyPaymentResponse>('payments/monthly/start', request),
  updateMonthlyPaymentSchedule: (request: UpdateMonthlyPaymentSechduleRequest) =>
    petApiService.post<UpdateMonthlyPaymentScheduleResponse>(
      'payments/monthly/schedule',
      request
    ),
  validateDirectDebit: (request: ValidateDirectDebitRequest) =>
    petApiService.post<BankDetails>('payments/monthly/validate', request),
  confirmMonthlyPayment: (request: ConfirmMonthlyPaymentRequest) =>
    petApiService.post('payments/monthly/confirmation', request),
};

export default paymentClient;
