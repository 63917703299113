import { InfoCard } from '@rsa-digital/evo-shared-components/components/InfoCard';
import {
  Heading,
  InfoCardRichText,
} from '@rsa-digital/evo-shared-components/components/InfoCard/InfoCard/styles';
import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import SwitchPaymentButton from 'components/SwitchPaymentButton';

export const StyledInfoCard = styled(InfoCard)`
  border: 0;
  border-radius: 0;
  border-top: 1px solid ${colors.neutral04};
  margin-top: ${spacing(2)};
  padding: ${spacing(4)} 0 0;
  & {
    ${Heading} {
      margin: 0;
    }
    ${InfoCardRichText} {
      text-align: left;
    }
  }
`;

export const PaymentTypeLabel = styled.span`
  ${fonts.headingXSmall}

  ${mediaQuery.tabletPortrait`
    ${fonts.headingSmall}
  `}
`;

export const PaymentAmount = styled.span`
  ${fonts.headingLarge};

  margin-top: ${spacing(2)};
`;

export const SwitchPaymentButtonWithTopMargin = styled(SwitchPaymentButton)<{
  centerContentsOnMobile: boolean;
}>`
  margin-top: ${spacing(1)};
  width: 100%;
  text-align: ${(p) => (p.centerContentsOnMobile ? 'center' : 'left')};
  ${mediaQuery.tabletPortrait`
    text-align: left;
  `}
`;

export const PriceSectionWrapper = styled(SimplePanel)<{
  centerContentsOnMobile: boolean;
  children?: React.ReactNode;
}>`
  display: flex;
  flex-direction: column;
  text-align: ${(p) => (p.centerContentsOnMobile ? 'center' : 'left')};
  ${mediaQuery.tabletPortrait`
    text-align: left;
  `}
  padding: ${spacing(3)} ${spacing(4)};
  margin-top: ${spacing(2)};
`;
