import React from 'react';
import CardPayment from 'components/Payment/AnnualPaymentPage/CardPayment';
import CancellationSignpost from 'components/Payment/CancellationSignpost';
import {
  MONTHLY_PAYMENT_PSEUDO_URL,
  PageTitle,
  trackSwitchPaymentClick,
} from 'helpers/eventTracking';
import { trackPageView } from 'helpers/pageTracking';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useReferenceData from 'state/referenceData/useReferenceData';
import PaymentSummarySection from '../PaymentSummarySection';

type AnnualPaymentPageProps = {
  switchPaymentType: () => void;
};

const AnnualPaymentPage: React.FC<AnnualPaymentPageProps> = ({ switchPaymentType }) => {
  const quote = useCurrentQuote();

  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? [];
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];

  const switchPayment: React.MouseEventHandler<Element> = () => {
    trackSwitchPaymentClick(PageTitle.Payment, 'monthly');
    switchPaymentType();
    trackPageView(
      MONTHLY_PAYMENT_PSEUDO_URL,
      PageTitle.Payment,
      quote,
      catBreedsRefData,
      dogBreedsRefData,
      undefined,
      undefined,
      'Monthly'
    );
  };
  const CancellationSignpostFlag = process.env.GATSBY_ANNUAL_SIGNPOSTING_FLAG;
  return (
    <>
      <PaymentSummarySection
        isAnnualPayment
        switchPaymentType={switchPayment}
        quote={quote}
      />
      {CancellationSignpostFlag === 'true' && <CancellationSignpost />}
      <CardPayment quote={quote} />
    </>
  );
};

export default AnnualPaymentPage;
