import { Divider } from '@rsa-digital/evo-shared-components/components/Divider/SimpleDivider/styles';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/MoreThan/fonts';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const StyledGrid = styled(Grid)`
  flex-direction: column;
`;

export const ParagraphText = styled.p`
  margin-bottom: ${spacing(2)};
`;

export const RichTextWithVerticalMargin = styled(RichTextWithModal)`
  margin: ${spacing(4)} 0 ${spacing(6)};
`;

export const PanelWithVerticalMargin = styled.div`
  margin: ${spacing(4)} 0;
`;

const ParagraphSpan = styled.span`
  ${fonts.paragraph}
`;

export const LightParagraphSpan = styled(ParagraphSpan)`
  & {
    display: block;
    font-size: 1.25rem;
    font-weight: ${lightFontWeight};
    ${mediaQuery.tabletLandscape`
      display: inline;
    `}
  }
`;

export const BoldParagraphSpan = styled(ParagraphSpan)`
  & {
    font-weight: bold;
  }
`;

export const Subheading = styled.h2`
  ${fonts.paragraphLarge};
  margin: ${spacing(4)} 0;
  ${mediaQuery.tabletLandscape`
    margin: ${spacing(3)} 0;
  `}
`;

export const DividerWithTopAndBottomMargin = styled(Divider)`
  margin-top: ${spacing(2)};
  margin-bottom: ${spacing(2)};
`;
