import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import { ErrorPanel } from '@rsa-digital/evo-shared-components/components/Panel/StatusPanel';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const ErrorText = styled.p`
  ${fonts.paragraph};
  margin: 0;
`;

export const ErrorPanelWithMargin = styled(ErrorPanel)`
  margin: ${spacing(1)} 0 ${spacing(2)};
`;

export const BankDetailsPanel = styled(SimplePanel)`
  margin: ${spacing(1)} 0 ${spacing(4)};
  padding: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    padding: ${spacing(4)};
  `}
`;

export const BankDetailsHeading = styled.p`
  ${fonts.headingXSmall}
  margin: 0;
  text-align: center;
`;

export const BankDetailsInfo = styled(BankDetailsHeading)`
  ${fonts.paragraphLarge}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(1.5)};
  `}
`;
