import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const IconWithMargin = styled(Icon)`
  margin-right: ${spacing(1)};
  width: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    margin-right: ${spacing(2)};
  `}
`;

// The button text will take two lines on mobile and small desktops
// This allows there to be padding between the border and text.
export const StyledButtonLink = styled(ButtonLink)<{ children?: React.ReactNode }>`
  min-height: ${spacing(4)};
  height: auto;
  display: flex;
  align-items: center;

  // Allows the content inside the button to wrap on IE
  max-width: 100%;

  ${mediaQuery.tabletLandscape`
    min-height: ${spacing(5)};
  `}

  ${fonts.paragraph}
  & {
    color: ${colors.core01};
    font-weight: normal;
  }

  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;
