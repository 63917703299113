import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler } from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CsIcon } from 'types/contentStack';
import {
  PaymentAmount,
  PaymentTypeLabel,
  PriceSectionWrapper,
  StyledInfoCard,
  SwitchPaymentButtonWithTopMargin,
} from './styles';

type CoverSummaryPricePanelProps = {
  isAnnualPayment: boolean;
  paymentOnClick: MouseEventHandler;
  centerContentsOnMobile?: boolean;
};

type CoverSummaryPricePanelCsProps = {
  csPetQuoteSummary: {
    monthly_payment_promo: {
      icon: [CsIcon];
      heading: string;
      body: string;
    };
  };
  csPetSummaryOfCoverInstallmentInfo: {
    annual_installment_text: string;
    monthly_installment_text: string;
    switch_to_annual_link_text: string;
    switch_to_monthly_link_text: string;
  };
};

export const query = graphql`
  query {
    csPetQuoteSummary {
      monthly_payment_promo {
        body
        heading
        icon {
          icon_code
        }
      }
    }
    csPetSummaryOfCoverInstallmentInfo {
      annual_installment_text
      monthly_installment_text
      switch_to_annual_link_text
      switch_to_monthly_link_text
    }
  }
`;

const CoverSummaryPricePanel: React.FC<CoverSummaryPricePanelProps> = ({
  isAnnualPayment,
  paymentOnClick,
  centerContentsOnMobile,
}) => {
  const {
    csPetQuoteSummary: { monthly_payment_promo },
    csPetSummaryOfCoverInstallmentInfo: {
      annual_installment_text,
      monthly_installment_text,
    },
  } = useStaticQuery<CoverSummaryPricePanelCsProps>(query);
  const MonthlyPaymentPromoIcon = unwrapSingleton(monthly_payment_promo.icon)?.icon_code;

  const quote = useCurrentQuote();

  const annualPrice = formatInPoundsWithSign(quote?.price?.annualPrice.total);
  const monthlyPrice = formatInPoundsWithSign(quote?.price?.monthlyPrice.total);

  return (
    <PriceSectionWrapper
      data-cy="coverSummaryPricePanel"
      borderShadows={{ displayOutset: false }}
      centerContentsOnMobile={centerContentsOnMobile ?? false}>
      <PaymentTypeLabel>
        {isAnnualPayment ? annual_installment_text : monthly_installment_text}
      </PaymentTypeLabel>
      <PaymentAmount>{isAnnualPayment ? annualPrice : monthlyPrice}</PaymentAmount>
      <SwitchPaymentButtonWithTopMargin
        isAnnualPayment={isAnnualPayment}
        switchPaymentType={paymentOnClick}
        centerContentsOnMobile={centerContentsOnMobile ?? false}
      />
      {isAnnualPayment && (
        <StyledInfoCard
          data-cy="monthlyPaymentPromo"
          heading={monthly_payment_promo.heading}
          body={monthly_payment_promo.body}
          headerIcon={MonthlyPaymentPromoIcon}
        />
      )}
    </PriceSectionWrapper>
  );
};

export default CoverSummaryPricePanel;
