import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/MoreThan/fonts';
import styled from 'styled-components';
import HeroBanner from 'components/Header/HeroBanner';

const ParagraphSpan = styled.span`
  ${fonts.paragraph}
`;

export const ParagraphText = styled.p`
  margin: ${spacing(4)} 0;
  ${mediaQuery.tabletLandscape`
    margin: ${spacing(2)} 0;
  `}
`;

export const BoldParagraphSpan = styled(ParagraphSpan)`
  & {
    font-weight: bold;
  }
`;

export const LightParagraphSpan = styled(ParagraphSpan)`
  & {
    font-weight: ${lightFontWeight};
  }
`;

export const PageHeading = styled(HeroBanner)`
  h1 {
    padding-bottom: 0;
  }
`;
