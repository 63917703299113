import {
  PageTitle,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackFormTextFieldFocus,
  trackRadioButtonClick,
  trackTextButtonClick,
  trackTooltipToggle,
} from 'helpers/eventTracking';

type AnalyticsEvent<EventType extends string, Data> = {
  type: EventType;
  data: Data;
};

type AnalyticsPayload =
  | AnalyticsEvent<'buttonClick', { buttonText: string }>
  | AnalyticsEvent<'radioButtonClick', { question: string; value: string }>
  | AnalyticsEvent<'formFieldEntry', { label: string }>
  | AnalyticsEvent<'dropdownSelector', { question: string }>
  | AnalyticsEvent<'primarySelector', { question: string }>
  | AnalyticsEvent<'tooltipClick', { label: string }>;

export const handleUppAnalytics = (payload: AnalyticsPayload): void => {
  /* istanbul ignore next */
  switch (payload.type) {
    case 'buttonClick':
      trackTextButtonClick(PageTitle.Payment, payload.data.buttonText);
      break;
    case 'radioButtonClick':
      trackRadioButtonClick(payload.data.question, payload.data.value);
      break;
    case 'formFieldEntry':
      trackFormTextFieldFocus(payload.data.label)();
      break;
    case 'dropdownSelector':
      trackFormDropdownSelect(payload.data.question);
      break;
    case 'primarySelector':
      trackFormDropdownFocus(payload.data.question, PageTitle.Payment)();
      break;
    case 'tooltipClick':
      trackTooltipToggle(PageTitle.Payment, payload.data.label);
      break;
    default:
      console.warn('Recieved unexpected UPP postMessage analytics payload:', payload);
  }
};
