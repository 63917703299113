export type StartAnnualPaymentResponse = {
  nbxChecksum: string;
  rsaChecksum: string;
  nbxMerchantReference: string;
  nbxSuccessUrl: string;
  policyMovementUpdateDate: string;
};

export type StartMonthlyPaymentResponse = {
  collectionAmount: number;
  quoteMovementUpdateDate: string;
};

export type UpdateMonthlyPaymentScheduleResponse = {
  collectionAmount: number;
  quoteMovementUpdateDate: string;
};

/* There exist other codes, but we only care about this code. This is looked for
 * separately because a code 6 is returned when it can't find the bank address but
 * is considered valid, so we need to accept the user's details in this case.
 */
export enum BankDetailReturnCodes {
  CODE6 = '6',
}

export type BankDetails = {
  bankName: string;
  branchName: string;
  returnCodes: BankDetailReturnCodes[];
};
