import LoadingOverlayV2 from '@rsa-digital/evo-shared-components/components/LoadingOverlayV2';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import SecurePaymentFooter from 'components/Footer/SecurePaymentFooter';
import Layout from 'components/Layout';
import AnnualPaymentPage from 'components/Payment/AnnualPaymentPage';
import MonthlyPaymentPage from 'components/Payment/MonthlyPaymentPage';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import useDefaultErrorHandling from 'helpers/errorHandling';
import { PageTitle } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/pageTracking';
import { useQuoteSummaryDetailsValidity } from 'helpers/quoteAndBuyPageFlowHelpers';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useLoadingState from 'helpers/useLoadingState';
import { CsHero } from 'types/contentStack';
import {
  BoldParagraphSpan,
  LightParagraphSpan,
  PageHeading,
  ParagraphText,
} from './styles';

const ECOMMERCE_STEP = 3;

type PaymentProps = {
  data: {
    csPetPayment: {
      meta_title: string;
      hero: CsHero;
    };
    csPetCheckYourDetailsDetailsSection: {
      quote_reference_label: string;
    };
    csPetGlobalConfig: {
      loading_spinner: {
        switch_payment_spinner_text: string;
      };
    };
  };
  location: Location & { state?: { detailsValid: boolean } };
};

export const query = graphql`
  query {
    csPetPayment {
      meta_title
      hero {
        heading
        subheading
      }
    }
    csPetCheckYourDetailsDetailsSection {
      quote_reference_label
    }
    csPetGlobalConfig {
      loading_spinner {
        switch_payment_spinner_text
      }
    }
  }
`;

const Payment: React.FC<PaymentProps> = ({
  data: {
    csPetPayment: { hero, meta_title },
    csPetCheckYourDetailsDetailsSection: { quote_reference_label },
    csPetGlobalConfig: {
      loading_spinner: { switch_payment_spinner_text },
    },
  },
  location: { state },
}) => {
  const quote = useCurrentQuote();
  const updateQuoteOptions = useUpdateQuoteOptions();
  const defaultErrorHandling = useDefaultErrorHandling();
  const areQuoteSummaryDetailsValid = useQuoteSummaryDetailsValidity();

  usePageTracking(meta_title, !!quote.customerInfo);
  useCheckoutTracking(ECOMMERCE_STEP, quote, true);

  const {
    isLoading: isSwitchingPayment,
    withLoadingState: switchPaymentWithLoading,
  } = useLoadingState();

  // const switchPaymentType = useCallback(() => {
  //   updateQuoteOptions({
  //     isAnnualPayment: !quote.quoteOptions.isAnnualPayment,
  //   });
  // }, [quote.quoteOptions.isAnnualPayment, updateQuoteOptions]);

  const switchPaymentType = async (): Promise<void> => {
    switchPaymentWithLoading(async () => {
      try {
        // await quoteUpdaters?.updateIsAnnualPayment(!quote.quoteOptions.isAnnualPayment);
        updateQuoteOptions({
          isAnnualPayment: !quote.quoteOptions.isAnnualPayment,
        });
      } catch (err) {
        const error: Error = err as Error;
        defaultErrorHandling(error);
      }
    });
  };

  if (quote.petInfos) {
    if (!areQuoteSummaryDetailsValid) {
      navigate(quoteAndBuyRoutes.quoteSummary);
    } else if (!state?.detailsValid) {
      navigate(quoteAndBuyRoutes.checkYourDetails);
    }
  }

  const loadingMessage = (): string => {
    if (isSwitchingPayment) return switch_payment_spinner_text;
    return '';
  };

  return (
    <LoadQuoteWrapper>
      <Layout
        currentStep={QuoteAndBuyStep.Payment}
        pageTitle={PageTitle.Payment}
        metaTitle={meta_title}>
        {isSwitchingPayment && (
          <LoadingOverlayV2 loadingMessage={loadingMessage()} timeDuration={10} />
        )}
        <ParagraphText data-cy="quoteReference">
          <LightParagraphSpan>{quote_reference_label} </LightParagraphSpan>
          <BoldParagraphSpan>{quote.policyInfo?.quoteNumber || ''}</BoldParagraphSpan>
        </ParagraphText>
        <PageHeading heading={hero.heading} subheading={hero.subheading} />
        {quote.quoteOptions.isAnnualPayment ? (
          <AnnualPaymentPage switchPaymentType={switchPaymentType} />
        ) : (
          <MonthlyPaymentPage switchPaymentType={switchPaymentType} />
        )}
      </Layout>
      {quote.quoteOptions.isAnnualPayment && <SecurePaymentFooter />}
    </LoadQuoteWrapper>
  );
};

export default Payment;
