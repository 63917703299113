export type PaymentInstallmentCover = {
  coverPurchasedIndicator?: boolean;
  coverSection?: string;
};

export enum PetDemandAndNeedCoverType {
  ACCIDENTS_AND_ILLNESS = 'AI',
}

export enum PetDemandAndNeedTreatmentType {
  SHORT_TERM_TREATMENTS = 'ST',
  ONGOING_TREATMENTS = 'SO',
}

type PaymentPetDetails = {
  coverLevelRequired: string;
  petType: string;
  cover: PaymentInstallmentCover[];
  demandAndNeedCoverType?: PetDemandAndNeedCoverType;
  demandAndNeedTreatmentType?: PetDemandAndNeedTreatmentType;
};

export type PolicyMovementDetails = {
  policyMovementUpdateDate: string;
  collection: {
    bankAccount: {
      dueDate?: number;
    };
  };
  pet: PaymentPetDetails[];
  quote: {
    quoteStartDate: string;
  };
  marketingCommunication: {
    marketingConsentFlag: boolean;
  };
};

export type PaymentAnnualDetails = {
  nbxPaymentAmount: string;
  nbxCurrencyCode: string;
  nbxEmail: string;
  rsaPaymentTransactionType: string;
  rsaDirectDebitAccountName?: string;
  rsaDirectDebitDueDate?: string;
  customDataField?: string;
};

export type BasePaymentRequest = {
  policyMovement: PolicyMovementDetails;
  quoteNumber: string;
};

export type StartAnnualPaymentRequest = {
  paymentDetails: PaymentAnnualDetails;
} & BasePaymentRequest;

export type ConfirmAnnualPaymentRequest = {
  quoteNumber: string;
  policyMovementUpdateDate: string;
};

export type StartMonthlyPaymentRequest = BasePaymentRequest;

export type UpdateMonthlyPaymentSechduleRequest = BasePaymentRequest;

export type ValidateDirectDebitRequest = {
  accountNumber: string;
  sortCode: string;
  quoteNumber: string;
};

type Party = {
  customerId: string;
  email: string;
  firstName: string;
  surname: string;
};

type MonthlyPaymentCollection = {
  collectionAmount: number;
  bankAccount: {
    accountName: string;
    accountNumber: string;
    sortCode: string;
  };
};

export type MonthlyConfirmationPolicyMovementDetails = {
  policyMovementUpdateDate: string;
  collection: MonthlyPaymentCollection;
  quote: {
    pets: {
      cover: {
        coverStartDate: string;
      }[];
    }[];
    party: Party;
  };
};

export type ConfirmMonthlyPaymentRequest = {
  policy: MonthlyConfirmationPolicyMovementDetails;
  quoteNumber: string;
};
