import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { IconWithMargin, StyledButtonLink } from 'components/SwitchPaymentButton/styles';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CsIcon } from 'types/contentStack';

type SwitchPaymentButtonProps = {
  isAnnualPayment: boolean;
  switchPaymentType: React.MouseEventHandler<Element>;
  hideIcon?: boolean;
} & ComponentProps;

const query = graphql`
  query {
    csPetPayment {
      switch_payment_buttons {
        switch_to_annual
        switch_to_monthly
        switch_icon {
          icon_code
        }
      }
    }
  }
`;

type SwitchPaymentButtonData = {
  csPetPayment: {
    switch_payment_buttons: {
      switch_to_annual: string;
      switch_to_monthly: string;
      switch_icon: [CsIcon];
    };
  };
};

const SwitchPaymentButton: React.FC<SwitchPaymentButtonProps> = ({
  isAnnualPayment,
  switchPaymentType,
  hideIcon,
  ...props
}) => {
  const data = useStaticQuery<SwitchPaymentButtonData>(query);

  const quote = useCurrentQuote();

  const plainTextPlaceholderReplacer = replacePlaceholdersPlainText(
    quotePlaceholders,
    quote
  );

  const switchIcon: CsIcon | undefined = unwrapSingleton(
    data.csPetPayment.switch_payment_buttons.switch_icon
  );
  const switchText = plainTextPlaceholderReplacer(
    isAnnualPayment
      ? data.csPetPayment.switch_payment_buttons.switch_to_monthly
      : data.csPetPayment.switch_payment_buttons.switch_to_annual
  );

  return (
    <StyledButtonLink onClick={switchPaymentType} {...componentProps(props)}>
      {switchIcon && !hideIcon && (
        <IconWithMargin name={switchIcon.icon_code} size="medium" displayVariant />
      )}
      {switchText}
    </StyledButtonLink>
  );
};

export default SwitchPaymentButton;
