import { getCoverLevelFromQuote } from 'apiHelpers/quote/bundleCoverMapping';
import { Quote } from 'apiHelpers/quote/quoteResponse';
import { CoverLevel } from 'helpers/businessConstants';
import mapPetPaymentInstallmentCovers from './mapPetPaymentInstallmentCovers';
import {
  PetDemandAndNeedCoverType,
  PetDemandAndNeedTreatmentType,
  PolicyMovementDetails,
} from '../paymentRequest';

const mapPolicyMovementDetails = (
  quote: Quote,
  paymentDay?: number
): PolicyMovementDetails => {
  const coverLevel = getCoverLevelFromQuote(quote);

  const demandAndNeedCoverType = PetDemandAndNeedCoverType.ACCIDENTS_AND_ILLNESS;

  const demandAndNeedTreatmentType = [
    CoverLevel.BASIC_1500,
    CoverLevel.BASIC_3000,
  ].includes(coverLevel)
    ? PetDemandAndNeedTreatmentType.SHORT_TERM_TREATMENTS
    : PetDemandAndNeedTreatmentType.ONGOING_TREATMENTS;

  return {
    policyMovementUpdateDate: quote.policyInfo?.quoteMovementUpdateDate ?? '',
    collection: {
      bankAccount: {
        dueDate: paymentDay ?? 1,
      },
    },
    pet:
      quote.petInfos?.map(({ petType, coverLevelRequired }) => ({
        coverLevelRequired,
        petType,
        cover: mapPetPaymentInstallmentCovers(quote, coverLevelRequired, petType),
        demandAndNeedCoverType,
        demandAndNeedTreatmentType,
      })) || [],
    quote: {
      quoteStartDate: quote.policyInfo?.quoteStartDate || '',
    },
    marketingCommunication: {
      marketingConsentFlag: quote.policyInfo?.contactable || false,
    },
  };
};

export default mapPolicyMovementDetails;
