import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import LoadingOverlayV2 from '@rsa-digital/evo-shared-components/components/LoadingOverlayV2';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { scrollToElement } from '@rsa-digital/evo-shared-components/helpers/scroll';
import useMonthlyPayment from 'apiHelpers/payment/useMonthlyPayment';
import { isQuoteOptionSelectionValid } from 'apiHelpers/quote/bundleCoverMapping';
import DirectDebitLogo from 'assets/directdebit.svg';
import { AxiosError } from 'axios';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import FormFooter from 'components/FormFooter';
import BankPayment from 'components/Payment/MonthlyPaymentPage/BankPayment';
import { trackPurchaseEvent } from 'helpers/ecommerceTracking';
import useDefaultErrorHandling from 'helpers/errorHandling';
import {
  ANNUAL_PAYMENT_PSEUDO_URL,
  PageTitle,
  trackAPIError,
  trackFieldError,
  trackSwitchPaymentClick,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { trackPageView } from 'helpers/pageTracking';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { useSaveAndEmailQuote } from 'helpers/saveQuoteHelpers';
import {
  PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY,
  removeSessionData,
  retrieveData,
  storeData,
} from 'helpers/sessionStorageHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useLoadingState from 'helpers/useLoadingState';
import useResetReduxState from 'helpers/useResetReduxState';
import { useConfirmationQuote } from 'state/quote/confirmationQuote';
import useReferenceData from 'state/referenceData/useReferenceData';
import useBankDetailsRules, { DirectDebitDetails } from './BankPayment/validation';
import { RichTextWithMargin, StyledImage, StyledPanel } from './styles';
import CancellationSignpost from '../CancellationSignpost';
import PaymentSummarySection from '../PaymentSummarySection';

type MonthlyPaymentPageContent = {
  csPetMonthlyPayment: {
    next_button_text: string;
    declaration: {
      information: string;
    };
  };
  csPetGlobalConfig: {
    loading_spinner: {
      email_spinner_text: string;
      buy_spinner_text: string;
      switch_payment_spinner_text: string;
      monthly_payment_spinner_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetMonthlyPayment {
      next_button_text
      declaration {
        information
      }
    }
    csPetGlobalConfig {
      loading_spinner {
        email_spinner_text
        buy_spinner_text
        switch_payment_spinner_text
        monthly_payment_spinner_text
      }
    }
  }
`;

export type MonthlyPaymentState = DirectDebitDetails & {
  collectionAmount?: number;
};

type MonthlyPaymentPageProps = {
  switchPaymentType: () => void;
};

const MonthlyPaymentPage: React.FC<MonthlyPaymentPageProps> = ({ switchPaymentType }) => {
  const {
    csPetMonthlyPayment: {
      next_button_text,
      declaration: { information },
    },
    csPetGlobalConfig: {
      loading_spinner: {
        email_spinner_text,
        buy_spinner_text,
        switch_payment_spinner_text,
        monthly_payment_spinner_text,
      },
    },
  } = useStaticQuery<MonthlyPaymentPageContent>(query);
  const [, updateConfirmationQuote] = useConfirmationQuote();

  const defaultErrorHandling = useDefaultErrorHandling();
  const resetReduxState = useResetReduxState();
  const quote = useCurrentQuote();
  const [switchingFromAnnual, setSwitchingFromAnnual] = useState(false);
  const { startMonthlyPayment, confirmMonthlyPayment } = useMonthlyPayment();
  const [isSetup, setIsSetup] = useState(false);
  const {
    isLoading: isSettingUpPayment,
    withLoadingState: setupPaymentWithLoading,
  } = useLoadingState();
  const {
    isLoading: isConfirmingPayment,
    withLoadingState: confirmPaymentWithLoading,
  } = useLoadingState();

  const [paymentState, setPaymentState] = useState<MonthlyPaymentState>({
    isAccountInName: undefined,
    accountHolderName: '',
    accountNumber: '',
    accountSortCode: {},
    monthlyPaymentDate: '01',
    bankDetails: { status: 'NONE' },
    paymentError: false,
  });

  const directDebitRules = useBankDetailsRules();

  const EMPTY: never[] = [];

  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? EMPTY;
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? EMPTY;

  const { getError, validateOnSubmit, showValidation } = useValidation(
    paymentState,
    {
      ...directDebitRules,
    },
    trackFieldError
  );

  const updatePaymentState = useCallback(
    (update: Partial<MonthlyPaymentState>) =>
      setPaymentState((state) => ({
        ...state,
        ...update,
      })),
    [setPaymentState]
  );

  const {
    saveAndEmailQuote,
    isSaveInProgress,
    savedQuoteConfirmationModal,
  } = useSaveAndEmailQuote(PageTitle.Payment);

  const onSubmitAndMoveNext = useCallback(
    async (details: MonthlyPaymentState): Promise<void> => {
      if (paymentState.bankDetails.status === 'FAILURE') {
        scrollToElement('bank-details-error', 20);
        return;
      }

      if (isConfirmingPayment) {
        return;
      }

      try {
        updatePaymentState({
          paymentError: false,
        });
        await confirmPaymentWithLoading(() => confirmMonthlyPayment(details));
        const confirmationQuote = { ...quote };
        trackPurchaseEvent(quote, catBreedsRefData, dogBreedsRefData);
        removeSessionData();
        resetReduxState();

        updateConfirmationQuote(confirmationQuote);
        navigate(quoteAndBuyRoutes.confirmation);
      } catch (err) {
        const error: AxiosError = err as AxiosError;
        trackAPIError(error);
        updatePaymentState({
          paymentError: true,
        });
        scrollToElement('payment-error', 20);
      }
    },
    [
      paymentState.bankDetails.status,
      isConfirmingPayment,
      updatePaymentState,
      confirmPaymentWithLoading,
      quote,
      resetReduxState,
      updateConfirmationQuote,
      confirmMonthlyPayment,
      catBreedsRefData,
      dogBreedsRefData,
    ]
  );

  useEffect(() => {
    if (quote.petInfos && !isSetup && !isSettingUpPayment) {
      setupPaymentWithLoading(async () => {
        try {
          const response = await startMonthlyPayment();
          updatePaymentState({
            collectionAmount: response,
          });
          setIsSetup(true);
        } catch (err) {
          const error: Error = err as Error;
          defaultErrorHandling(error);
        }
      });
    }
  }, [
    defaultErrorHandling,
    isSettingUpPayment,
    isSetup,
    setupPaymentWithLoading,
    startMonthlyPayment,
    updatePaymentState,
    quote.petInfos,
  ]);

  const switchPayment: React.MouseEventHandler<Element> = () => {
    trackSwitchPaymentClick(PageTitle.Payment, 'annually');
    switchPaymentType();
    trackPageView(
      ANNUAL_PAYMENT_PSEUDO_URL,
      PageTitle.Payment,
      quote,
      catBreedsRefData,
      dogBreedsRefData,
      undefined,
      undefined,
      'Annual'
    );
  };

  // const preparePaymentScreenReaderMessage = quote.quoteOptions.isAnnualPayment
  //   ? `Preparing an annual payment of ${formatInPoundsWithSign(
  //       quote.price?.annualPrice.total
  //     )}`
  //   : `Preparing 12 monthly payments of ${formatInPoundsWithSign(
  //       quote.price?.monthlyPrice.total
  //     )}`;

  useEffect(() => {
    const previousPaymentMode = retrieveData(PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY);
    if (previousPaymentMode === 'ANNUAL') {
      setSwitchingFromAnnual(true);
      storeData(PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY, 'MONTHLY');
    }
  }, []);

  const settingUpPaymentLoadingMessage = switchingFromAnnual
    ? switch_payment_spinner_text
    : monthly_payment_spinner_text;

  const loadingMsgText = (): string => {
    if (isConfirmingPayment) return buy_spinner_text;
    if (isSaveInProgress) return email_spinner_text;
    if (isSettingUpPayment) return settingUpPaymentLoadingMessage;
    return '';
  };

  return (
    <>
      {(isSettingUpPayment || isConfirmingPayment || isSaveInProgress) && (
        <LoadingOverlayV2
          id={`${isSettingUpPayment ? 'preparing_payment' : null}`}
          loadingMessage={loadingMsgText()}
          timeDuration={10}
        />
      )}
      <form
        onSubmit={validateOnSubmit(
          () => onSubmitAndMoveNext(paymentState),
          scrollAndTrackError
        )}>
        <PaymentSummarySection
          isAnnualPayment={false}
          switchPaymentType={switchPayment}
          quote={quote}
        />
        <BankPayment
          details={paymentState}
          updateCollectionAmount={(collectionAmount) =>
            updatePaymentState({
              collectionAmount,
            })
          }
          updateDetails={updatePaymentState}
          getError={getError}
          showValidation={showValidation}
        />
        <Grid alignLeft>
          <GridItem desktop={10} tabletLandscape={10}>
            <StyledPanel borderShadows={{ displayOutset: true }} id="declaration">
              <StyledImage src={DirectDebitLogo} alt="Direct Debit Logo" />
              <RichTextWithMargin pageTitle={PageTitle.Payment} html={information} />
            </StyledPanel>
          </GridItem>
        </Grid>
        <CancellationSignpost />
        <FormFooter
          contentColumns={{ desktop: 10, tabletLandscape: 10 }}
          backButton={{
            onClick: () => {
              trackTextButtonClick(PageTitle.Payment, 'Back');
              navigate(quoteAndBuyRoutes.checkYourDetails);
            },
          }}
          moveNextButton={{
            text: next_button_text,
            onClick: () => trackTextButtonClick(PageTitle.Payment, 'Buy now'),
          }}
          saveButton={
            isQuoteOptionSelectionValid(quote.quoteOptions)
              ? {
                  onClick: saveAndEmailQuote,
                }
              : undefined
          }
          pageTitle={PageTitle.Payment}
        />
        {savedQuoteConfirmationModal}
      </form>
    </>
  );
};

export default MonthlyPaymentPage;
